@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600&display=swap');

* { font-display: swap;}
body {
  margin: 0;
  font-family: 'Poppins', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #FFFFFF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-uppercase {
  text-transform: uppercase;
}

@media (max-width: 500px) {
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }
}


/*
    ==========
      LOADER
    ==========
*/
.loader-container:not(.active) {
  display: none;
}
.loader-container {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 999999;
}
.loader-container .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
}
.lds-ai {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); 
}
/* .lds-ai div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #fff;
  animation: lds-ai 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-ai div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-ai div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-ai div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-ai {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
} */

/*
    ==========
      Toggle
    ==========
*/
input.toggle:checked ~ .dot {
  transform: translateX(150%);
}

.text-gradient {
    background: linear-gradient(90deg, #3f51b5, transparent) #3fcad7;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.gradient-hover-effect {
  display: flex;
  padding: 0.875em 2em;
  background: linear-gradient(90deg, #3f51b5, transparent) #2196f3;
  font-family: inherit;
  color: #fff;
  text-decoration: none;
  transition: background-color 1s;
  place-content: center;
}

.gradient-hover-effect:hover, .gradient-hover-effect:focus {
    background-color: #F7941D !important;
    color: #fff;
}

i.fas, i.fa {
    font-size: 1.5rem;
    margin: 0 0 0 7px;
}

.features i.fas, .features i.fa {
    font-size: 1rem;
    margin: 0 5px 0 0;
}
.ctabtn {
    box-shadow: 0 11px 12px -10px #282828 !important;
}

.ask {
    background: rgb(171,211,238);
    background: -moz-radial-gradient(ellipse at 50% 150%, rgba(171,211,238,1) 0%, rgba(235,241,246,1) 50%, rgba(236,241,246,1) 62%, rgba(243,244,246,1) 100%);
    background: -webkit-radial-gradient(ellipse at 50% 150%, rgba(171,211,238,1) 0%, rgba(235,241,246,1) 50%, rgba(236,241,246,1) 62%, rgba(243,244,246,1) 100%);
    background: radial-gradient(ellipse at 50% 150%, rgba(171,211,238,1) 0%, rgba(235,241,246,1) 50%, rgba(236,241,246,1) 62%, rgba(243,244,246,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#abd3ee', endColorstr='#f3f4f6',GradientType=1 );
}

::-ms-reveal,
input[name="password"]::-ms-reveal,
input[name="password"]::-ms-clear {
  display: none;
}

input[name="password"]::-webkit-contacts-auto-fill-button,
input[name="password"]::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  height: 0;
  width: 0;
  margin: 0;
}


.custom-scrollbar {
  width: 300px;
  overflow: auto;
}

/* For WebKit browsers (Chrome, Safari) */
.custom-scrollbar::-webkit-scrollbar {
  width: 30px !important; /* Width of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent; /* Background of the track */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: transparent; /* Color of the scrollbar thumb */
  border-radius: 6px; /* Rounded corners */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background:  transparent; /* Color when hovering over the thumb */
}


.no-scrollbar::-webkit-scrollbar {
  display: none; /* Hides scrollbar in WebKit-based browsers */
}
.no-scrollbar::-webkit-scrollbar-track {
  background: transparent; /* Background of the track */
}

.no-scrollbar::-webkit-scrollbar-thumb {
  background: transparent; /* Color of the scrollbar thumb */
  border-radius: 6px; /* Rounded corners */
}

.no-scrollbar {
  -ms-overflow-style: none; /* Hides scrollbar in IE and Edge */
  scrollbar-width: none; /* Hides scrollbar in Firefox */
  overflow-y: scroll; /* Ensures the element remains scrollable */
}

.no-scrollbar::-webkit-scrollbar-thumb:hover {
  background:  transparent; /* Color when hovering over the thumb */
}
